import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { MdExpandMore } from "react-icons/md";
import "./MultiSelectDropdown.scss";
import CheckboxInput from "../../../utils/Components/InputComps/CheckboxInput/CheckboxInput";
import { postAuthData } from "../../../services/request";
import toast from "react-hot-toast";

const MultiSelectDropdown = ({ assignId, part_id = null, role = null, placeholder }) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const options = ["TMX", "XLSX", "MXLIFF"];

  const toggleOption = (option) => {
    setSelectedOptions((prev) => (prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]));
  };

  const handleGetDownloadLink = async () => {
    if (selectedOptions.length === 0) {
      toast.error("Please select at least one format to download", { id: "file-download" });
      return;
    }

    if (!assignId) {
      toast.error("Assignment ID is required", { id: "file-download" });
      return;
    }

    setIsLoading(true);

    try {
      const URL = `${process.env.REACT_APP_WORKSPACE_SUPPORT_API}/api/workspace-support/editor-info/translations/files`;

      const data = {
        part_id: part_id ? part_id : null,
        role: role ? role : null,
        assignment_id: assignId,
        translation_memory_data: {
          tmx: selectedOptions.includes("TMX"),
          xlsx: selectedOptions.includes("XLSX"),
          mxliff: selectedOptions.includes("MXLIFF"),
        },
      };
      const response = await postAuthData(URL, data);

      if (!response.success) {
        throw new Error(response.message || "Failed to get download links");
      }

      const downloadPromises = [];

      if (response.data?.tmx_data?.status === "Completed") {
        downloadPromises.push(downloadURL(response.data.tmx_data.file_link, response.data.tmx_data.file_name));
      }

      if (response.data?.xlsx_data?.status === "Completed") {
        downloadPromises.push(downloadURL(response.data.xlsx_data.file_link, response.data.xlsx_data.file_name));
      }

      if (response.data?.mxliff_data?.status === "Completed") {
        downloadPromises.push(downloadURL(response.data.mxliff_data.file_link, response.data.mxliff_data.file_name));
      }

      await Promise.all(downloadPromises);
    } catch (error) {
      toast.error(error.message || "Failed to download files", { id: "file-download" });
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadURL = async (url, file_name) => {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}`);
      }

      // Check headers
      const contentType = response.headers.get("Content-Type");

      const arrayBuffer = await response.arrayBuffer();
      const blob = new Blob([arrayBuffer], { type: contentType });

      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = blobUrl;
      a.download = file_name || "downloaded_file";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobUrl);
      a.remove();

      return Promise.resolve(); // Resolve the promise after the download is complete
    } catch (error) {
      console.error("Download failed:", error);
      return Promise.reject(error); // Reject the promise if there's an error
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (assignId) {
      setSelectedOptions([]);
    }

    return () => {
      setSelectedOptions([]);
    };
  }, [assignId]);

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      {/* Dropdown Button */}
      <button onClick={() => setIsOpen(!isOpen)} className={`dropdown-button ${isOpen ? "open" : ""}`}>
        <span className="dropdown-label">{selectedOptions.length === 0 ? placeholder : `${selectedOptions.length} selected`}</span>
        <MdExpandMore className={`icon ${isOpen ? "rotate" : ""}`} />
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="dropdown-menu">
          <div className="menu-options">
            {options.map((option) => (
              <label key={option} className="menu-option" onClick={() => toggleOption(option)}>
                <div className="checkbox-container">
                  <CheckboxInput
                    id={option}
                    name={option}
                    label={option}
                    defaultChecked={selectedOptions.includes(option)}
                    onChange={() => toggleOption(option)}
                  ></CheckboxInput>
                </div>
              </label>
            ))}
          </div>

          {/* Download Button */}
          <div className="menu-footer">
            <button onClick={handleGetDownloadLink} disabled={selectedOptions.length === 0} className="download-button">
              {isLoading ? (
                "Downloading..."
              ) : (
                <>
                  <AiOutlineDownload className="download-icon" />
                  Download
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
